import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'features/User/reducers';
import { ConfigProvider } from 'antd';
import RouterPath from '../constants/RouterPath';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const TheLayout = React.lazy(() => import('../components/Common/TheLayout'));
const PublicLayout = React.lazy(() =>
  import('../components/Common/PublicLayout'),
);
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);
export default function AppRouter() {
  const { i18n } = useTranslation();
  const [userState] = useUserState();
  const { userLanguage } = userState;

  useEffect(() => {
    i18n.changeLanguage(userLanguage);
  }, [userLanguage, i18n]);

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          {/* <PublicRoute path={RouterPath.SIGNUP} exact> */}
          {/*	<PublicLayout /> */}
          {/* </PublicRoute> */}
          {/* <PublicRoute path={RouterPath.FORGOT_PASSWORD} exact> */}
          {/*	<PublicLayout /> */}
          {/* </PublicRoute> */}

          <PublicRoute path={RouterPath.LOGIN} exact>
            <PublicLayout />
          </PublicRoute>
          {/* </ConfigProvider> */}

          {/* <PublicRoute path={RouterPath.SIGNUP_PHONE} exact> */}
          {/*	<SignUpPhone /> */}
          {/* </PublicRoute> */}

          {/* <ForgotPasswordPrivateRoute path={RouterPath.FORGOT_PASSWORD}> */}
          {/*	<Switch> */}
          {/*		<Route exact path={RouterPath.FORGOT_PASSWORD_OTP}> */}
          {/*			<ForgotPasswordOtpScreen /> */}
          {/*		</Route> */}
          {/*		<Route exact path={RouterPath.NEW_PASSWORD}> */}
          {/*			<NewPasswordScreen /> */}
          {/*		</Route> */}
          {/*	</Switch> */}
          {/* </ForgotPasswordPrivateRoute> */}

          {/* <Route exact path={RouterPath.SIGNUP_PHONE}> */}
          {/*	<SignUpPhone /> */}
          {/* </Route> */}
          {/* <Route exact path={RouterPath.PROFILES}> */}
          {/*	<Profiles /> */}
          {/* </Route> */}
          {/* <SignUpPrivateRoute path={RouterPath.SIGNUP}> */}
          {/*	<Switch> */}
          {/*		<Route exact path={RouterPath.SIGNUP_SOCIAL}> */}
          {/*			<SignUpSocial /> */}
          {/*		</Route> */}
          {/*		<Route exact path={RouterPath.OTP}> */}
          {/*			<OTP /> */}
          {/*		</Route> */}
          {/*	</Switch> */}
          {/* </SignUpPrivateRoute> */}
          <ConfigProvider
            theme={{
              components: {
                Tag: {
                  colorPrimary: '#15BA92',
                },
                Card: {
                  colorBorderSecondary: '#33CC7F21',
                  lineWidth: 4,
                },
                Input: {
                  colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
                },
                Button: {
                  colorLink: '#1F7A4C',
                },
              },
              token: {
                colorPrimary: '#26A68D',
                colorBorder: '#26A68D',
                colorBgLayout: '#FDFDFD',
              },
            }}
          >
            <PrivateRoute path={RouterPath.INSTRUCTIONS_PAGE}>
              <TheLayout />
            </PrivateRoute>
          </ConfigProvider>
        </Switch>
      </React.Suspense>
    </Router>
  );
}
