export enum ActionType {
  'LOADING_BUTTON' = 'LOADING_BUTTON',
}

export type StoreState = {
  loadingButton: boolean;
  loadingTable: boolean;
  loading: boolean;
  headerTitle: string;
};
