import ConstructionApi from 'services/api/construction';

// eslint-disable-next-line import/prefer-default-export
export const constructionService = {
  updateConstruction,
  createConstruction,
  getConstructionById,
  cancelComplementRequest,
  uploadBillPaymentViolation,
  putMapping,
  postMapping,
  getMapping,
  deleteMapping,
};

async function updateConstruction({ params, formData }: any, thunkAPI: any) {
  const constructionApi = new ConstructionApi();

  try {
    return await constructionApi.updateConstruction(params, formData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function createConstruction(formData: any, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.createConstruction(formData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function getConstructionById(constructionId: number, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.getConstructionById(constructionId);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function cancelComplementRequest(
  { constructionId, values }: any,
  thunkAPI: any,
) {
  const constructionApi = new ConstructionApi();
  try {
    const path = `complements/${constructionId}/cancel`;
    return await constructionApi.putMapping(path, values);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function putMapping({ path, body }: any, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.putMapping(path, body);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}
async function postMapping({ path, body }: any, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.postMapping(path, body);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function uploadBillPaymentViolation(formData: FormData, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.uploadBillPaymentViolation(formData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function getMapping({ path, params }: any, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.getMapping(path, params);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}

async function deleteMapping({ path, params }: any, thunkAPI: any) {
  const constructionApi = new ConstructionApi();
  try {
    return await constructionApi.deleteMapping(path, params);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
}
