import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import RouterPath from '../../constants/RouterPath';
import { UserStateType } from '../../features/User/types';

interface ProtectedRouteProps {
  children: ReactNode;
  path: string;
}

export default function PrivateRoute({
  children,
  ...rest
}: ProtectedRouteProps) {
  const userState = useSelector((state: any) => state.user) as UserStateType;
  const { isLoggedIn } = userState;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{ pathname: RouterPath.LOGIN, state: { from: location } }}
          />
        )
      }
    />
  );
}
