/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { constructionService } from 'services/constructionService';
import { ActionType, StoreState } from '../../../types/ActionType';
import { TITLE_HEADER } from '../../../types/Layout/Layout.d';

const initialState: StoreState = {
  loadingButton: false,
  loadingTable: false,
  loading: false,
  headerTitle: '',
};
export const updateConstruction = createAsyncThunk(
  'construction/updateConstruction',
  constructionService.updateConstruction,
);

export const getConstructionById = createAsyncThunk(
  'construction/getConstruction',
  constructionService.getConstructionById,
);

export const createConstruction = createAsyncThunk(
  'construction/createConstruction',
  constructionService.createConstruction,
);

export const cancelComplementRequest = createAsyncThunk(
  'complement/cancelRequest',
  constructionService.cancelComplementRequest,
);
export const putMapping = createAsyncThunk(
  'api/putMapping',
  constructionService.putMapping,
);

export const postMapping = createAsyncThunk(
  'api/postMapping',
  constructionService.postMapping,
);

export const getMapping = createAsyncThunk(
  'api/getMapping',
  constructionService.getMapping,
);

export const deleteMapping = createAsyncThunk(
  'api/deleteMapping',
  constructionService.deleteMapping,
);
export const uploadBillPaymentViolation = createAsyncThunk(
  'api/uploadBillPaymentViolation',
  constructionService.uploadBillPaymentViolation,
);

const constructionSlice = createSlice({
  name: 'construction',
  initialState,
  reducers: {
    loading(state, action: PayloadAction<ActionType>) {
      switch (action.payload) {
        case ActionType.LOADING_BUTTON:
          state.loadingButton = !state.loadingButton;
          break;
        default:
      }
    },
    setTitleHeader(state, action: PayloadAction<string>) {
      if (action && action.payload !== '') {
        state.headerTitle = action.payload;
      } else {
        let result = '';
        const locationPath = window.location.pathname;
        for (const key of Object.keys(TITLE_HEADER)) {
          if (locationPath.startsWith(key)) {
            result = (TITLE_HEADER as any)[key];
            break;
          }
        }

        state.headerTitle = result;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateConstruction.pending, (state) => {
      state.loadingButton = true;
    });
    builder.addCase(updateConstruction.fulfilled, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(updateConstruction.rejected, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(createConstruction.pending, (state) => {
      state.loadingButton = true;
    });
    builder.addCase(createConstruction.fulfilled, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(createConstruction.rejected, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(getConstructionById.pending, (state) => {
      state.loadingButton = true;
      state.loading = true;
    });
    builder.addCase(getConstructionById.fulfilled, (state) => {
      state.loadingButton = false;
      state.loading = false;
    });
    builder.addCase(getConstructionById.rejected, (state) => {
      state.loadingButton = false;
      state.loading = false;
    });
    builder.addCase(cancelComplementRequest.pending, (state) => {
      state.loadingButton = true;
    });
    builder.addCase(cancelComplementRequest.fulfilled, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(cancelComplementRequest.rejected, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(putMapping.pending, (state) => {
      state.loadingButton = true;
    });
    builder.addCase(putMapping.fulfilled, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(putMapping.rejected, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(postMapping.pending, (state) => {
      state.loadingButton = true;
    });
    builder.addCase(postMapping.fulfilled, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(postMapping.rejected, (state) => {
      state.loadingButton = false;
    });
    builder.addCase(getMapping.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMapping.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getMapping.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(uploadBillPaymentViolation.pending, (state) => {
      state.loadingTable = true;
    });
    builder.addCase(uploadBillPaymentViolation.fulfilled, (state) => {
      state.loadingTable = false;
    });
    builder.addCase(uploadBillPaymentViolation.rejected, (state) => {
      state.loadingTable = false;
    });
  },
});
// export const useConstructionState = () => {
//   const constructionState = useSelector(
//     (state: RootState) => state.construction,
//   );
//   const dispatch = useAppDispatch();
//
//   const constructionActions = {
//     loadingButton: (params: ActionType) =>
//       dispatch(constructionSlice.actions.loading(params)),
//   };
//
//   return [constructionState, constructionActions] as any;
// };
export default constructionSlice;
