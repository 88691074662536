export type SideBarProps = {
  nav: NavProps[];
};

export type NavProps = {
  tag: string;
  name: string;
  to: string;
  icon?: JSX.Element;
  children?: NavProps[];
};

export type RouteProps = {
  path: string;
  exact: boolean;
  name?: string;
  permission?: string[];
  component: any;
};

export enum TITLE_HEADER {
  '/construction/request-complete/' = 'Yêu cầu hoàn công',
  '/construction/register' = 'Đăng ký thi công',
  '/construction/update/' = 'Đăng ký thi công',
  '/construction/detail/' = 'Chi tiết hồ sơ đăng ký thi công',
  '/construction/request-change-agent-detail/' = 'Yêu cầu thay đổi người đại diện',
  '/construction/request-change-agent/' = 'Yêu cầu thay đổi người đại diện',
  '/construction/request-for-change/' = 'Yêu cầu thay đổi hạng mục thi công và gia hạn',
  '/construction/request-pause/' = 'Yêu cầu tạm dừng thi công',
  '/construction/request-continue/' = 'Yêu cầu tiếp tục thi công',
  '/user/profile' = 'Cổng đăng ký thi công',
  '/user/change-password' = 'Cổng đăng ký thi công',
  '/construction' = 'Quản lý hồ sơ đăng ký thi công',

  '/' = 'Cổng đăng ký thi công',
}
export enum TITLE_HEADER_REQUEST_TYPE {}
