import { ShapeFormConstruction } from 'types/construction';
import Api from './api';
import {
  GetConstructionResponseType,
  GetConstructionSuccessType,
  GetDelegacyProfileResponseType,
  GetDelegacyProfileSuccessType,
} from './types/construction';

export default class ConstructionApi extends Api {
  path: string;

  constructor() {
    super(process.env.REACT_APP_BUSINESS_SERVER_PATH);
    this.path = '/api';
  }

  async requestDelegacyProfileConstruction(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/complements/delegacy-profile`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async requestContinueConstruction(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/complements/continue`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async requestCreateDepositRefund(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/complements/deposit-refund`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async requestPauseConstruction(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/complements/pause`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async updateExtendAndChange(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/complements/extend-and-change`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async createConstruction(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/construction-registrations`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async uploadBillPaymentConstruction(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/receipt-document`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async updateConstruction(id: string | number, formData: FormData) {
    const res = await this.request({
      path: `${this.path}/construction-registrations/${id}?_method=PUT`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async getAdditionalRequestByID(id: string | number) {
    const res = await this.request({
      path: `${this.path}/construction-registrations/${id}/complements`,
      method: 'GET',
    });

    return res;
  }

  async getReportByID(id: string | number) {
    const res = await this.request({
      path: `${this.path}/construction-registrations/${id}/violate-records`,
      method: 'GET',
    });

    return res;
  }

  async getConstruction(
    body: ShapeFormConstruction,
  ): Promise<GetConstructionResponseType> {
    const res = await this.request<GetConstructionSuccessType>({
      path: `${this.path}/construction-registrations`,
      method: 'GET',
      params: body,
    });
    return res;
  }

  async getConstructionById(id: number): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/construction-registrations/${id}`,
      method: 'GET',
      params: {},
    });
    return res;
  }

  async getDelegacyProfile(): Promise<GetDelegacyProfileResponseType> {
    const res = await this.request<GetDelegacyProfileSuccessType>({
      path: `${this.path}/delegacy-profiles/me`,
      method: 'GET',
      params: {},
    });
    return res;
  }

  async cancelConstructionById(id: number, body: any): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/construction-registrations/${id}/cancel`,
      method: 'PUT',
      body,
    });
    return res;
  }

  async cancelComplementById(id: number, body: any): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/complements/${id}/cancel`,
      method: 'PUT',
      body,
    });
    return res;
  }

  async getLocationTypeByLocationCode(locationCode: string): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/locations/${locationCode}/location-type`,
      method: 'GET',
      params: {},
    });
    return res;
  }

  async uploadBillPaymentViolation(formData: FormData) {
    const res = await this.request({
      path: `${this.path}/violate-records/bill`,
      method: 'POST',
      body: formData,
    });

    return res;
  }

  async checkContractUsed(contractCode: string): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/construction-registrations/contract/${contractCode}/used`,
      method: 'GET',
      params: {},
    });
    return res;
  }

  async getConstructionLicensesById(id: string | number): Promise<any> {
    const res = await this.request({
      path: `${this.path}/construction-registrations/${id}/licenses`,
      method: 'GET',
      params: {},
    });

    return res;
  }

  async getMapping(path: string, params?: any): Promise<any> {
    const res = await this.request({
      path: `${this.path}/${path}`,
      method: 'GET',
      params,
    });

    return res;
  }

  async deleteMapping(path: string, params?: any): Promise<any> {
    const res = await this.request({
      path: `${this.path}/${path}`,
      method: 'DELETE',
      params,
    });
    return res;
  }

  async postMapping(path: string, body: any): Promise<any> {
    const res = await this.request({
      path: `${this.path}/${path}`,
      method: 'POST',
      body,
    });

    return res;
  }

  async putMapping(path: string, body: any): Promise<any> {
    const res = await this.request<any>({
      path: `${this.path}/${path}`,
      method: 'PUT',
      body,
    });
    return res;
  }
}
