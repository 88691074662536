export default {
  HOME_PAGE: '/home',
  INSTRUCTIONS_PAGE: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password/otp',
  NEW_PASSWORD: '/forgot-password/new-password',
  SIGNUP: '/signup',
  SIGNUP_PHONE: '/signup/phone',
  SIGNUP_SOCIAL: '/signup/social',
  OTP: '/signup/otp',
  PROFILES: '/dashboard/profiles',
  PRODUCTS: '/dashboard/products',
  CONSTRUCTION_MANAGEMENT_PAGE: '/construction',
  CONSTRUCTION_REGISTER_PAGE: '/construction/register',
  CONSTRUCTION_UPDATE_REGISTER_PAGE: '/construction/update/:identifier',
  CONSTRUCTION_DETAIL_PAGE: '/construction/detail/:identifier',
  CONSTRUCTION_REQUEST_COMPLETE_PAGE:
    '/construction/request-complete/:identifier',
  CONSTRUCTION_REQUEST_CONTINUE_PAGE:
    '/construction/request-continue/:identifier',
  CONSTRUCTION_REQUEST_CHANGE_AGENT_PAGE:
    '/construction/request-change-agent/:identifier',
  CONSTRUCTION_REQUEST_FOR_CHANGE_PAGE:
    '/construction/request-for-change/:identifier',
  CONSTRUCTION_REQUEST_PAUSE_PAGE: '/construction/request-pause/:identifier',
  CONSTRUCTION_REQUEST_DETAIL_PAGE: '/construction/request-detail/:identifier',
  CONSTRUCTION_REQUEST_CHANGE_AGENT_DETAIL_PAGE:
    '/construction/request-change-agent-detail/:identifier',

  PROFILE_PAGE: '/user/profile',
  UPDATE_PROFILE_PAGE: '/user/profile/:identifier',
  CHANGE_PASSWORD_PAGE: '/user/change-password',
  DASHBOARD_PAGE: '*',
};
