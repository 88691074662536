import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import RouterPath from '../../constants/RouterPath';
import { UserStateType } from '../../features/User/types';

interface ProtectedRouteProps {
  children: ReactNode;
  path: string;
  exact?: boolean;
}

export default function PublicRoute({
  children,
  ...rest
}: ProtectedRouteProps) {
  const userState = useSelector((state: any) => state.user) as UserStateType;
  const { isLoggedIn } = userState;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          <Redirect
            to={{
              pathname: RouterPath.INSTRUCTIONS_PAGE,
              state: { from: location },
            }}
          />
        ) : (
          children
        );
      }}
    />
  );
}
