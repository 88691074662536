export interface OauthTokenType {
  accessToken: string;
  tokenType: 'Bearer';
  expiresIn: number;
  refreshToken: string;
  createdAt: number;
}

// export type LanguageType = 'en' | 'vn';
export enum LanguageType {
  VN = 'vn',
  EN = 'en',
}

export interface UserType {
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  initials?: string;
  profileImage?: string;
  notifications?: any[];
  avatar?: string;
  phoneNumber?: string;
  status?: 'active' | 'inactive';
  resetPasswordToken?: string;
  token?: OauthTokenType;
  reachedOnboardingScreen?: boolean;
  permissionRole?: 'user' | 'root';
}

export interface UserStateType {
  user?: UserType;
  userToken?: OauthTokenType;
  isLoggedIn: boolean;
  userLanguage: LanguageType;
  apiError?: string;
  loadingButton: boolean;
}

export interface SignInPayloadType {
  user: UserType;
  token: OauthTokenType;
}
