import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SignInParamsType,
  SignInWithThirdPartiesParamsType,
  SignUpParamsType,
} from 'services/api/types/user';
import UserApi from 'services/api/user';
import { RootState } from 'store';
import { SignInPayloadType } from '../types';

export const signIn = createAsyncThunk(
  'user/requestSignIn',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: SignInParamsType, { rejectWithValue }) => {
    const userApi = new UserApi();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res = await userApi.signIn(params);

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data.user,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const signInWithThirdParties = createAsyncThunk(
  'user/signInWithThirdParties',
  async (params: SignInWithThirdPartiesParamsType, { rejectWithValue }) => {
    const userApi = new UserApi();
    const res = await userApi.signInWithThirdParties(params);

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const activateUser = createAsyncThunk(
  'user/activateUser',
  async (phoneNumber: string, { getState, rejectWithValue }) => {
    const userState = (getState() as RootState).user;
    const { user } = userState;
    const userApi = new UserApi();
    const res = await userApi.activateUser({
      phoneNumber,
      userId: user!.id,
    });
    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);

export const verifyOTP = createAsyncThunk(
  'user/verifyOTP',
  async (OTP: string, { getState, rejectWithValue }) => {
    const userState = (getState() as RootState).user;
    const { user } = userState;
    const userApi = new UserApi();
    const res = await userApi.verifyOTP({
      OTP,
      userId: user!.id,
    });
    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);

export const signUp = createAsyncThunk(
  'user/signUp',
  async (params: SignUpParamsType, { rejectWithValue }) => {
    const userApi = new UserApi();
    const res = await userApi.signUp(params);

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const requestForgotPassword = createAsyncThunk(
  'user/requestForgotPassword',
  async (phoneNumber: string, { rejectWithValue }) => {
    const userApi = new UserApi();
    const res = await userApi.requestForgotPassword(phoneNumber);

    if (res.status === 'success') {
      return Promise.resolve({ ...res.result.data.user, phoneNumber });
    }
    return rejectWithValue(res.errors);
  },
);

export const signInOtp = createAsyncThunk(
  'user/signInOtp',
  async (otp: string, { getState, rejectWithValue }) => {
    const userState: any = (getState() as RootState).user;
    const { user } = userState;
    const userApi = new UserApi();
    const res = await userApi.signInOtp({ otp, userId: user!.id });

    if (res.status === 'success') {
      return Promise.resolve({
        user: res.result.data,
        token: res.result.data.token,
      } as SignInPayloadType);
    }
    return rejectWithValue(res.errors);
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (password: string, { getState, rejectWithValue }) => {
    const userState: any = (getState() as RootState).user;
    const { user } = userState;
    const userApi = new UserApi();
    const res = await userApi.resetPassword({
      password,
      passwordConfirmation: password,
      passwordResetToken: user!.resetPasswordToken!,
    });

    if (res.status === 'success') {
      return Promise.resolve(res);
    }
    return rejectWithValue(res.errors);
  },
);

export const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (params: any, thunkAPI) => {
    const userApi = new UserApi();
    try {
      const res = await userApi.updateUserInfo(params);
      if (res.status === 'success') {
        return Promise.resolve(res);
      }
      return thunkAPI.rejectWithValue(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (params: any, thunkAPI) => {
    const userApi = new UserApi();
    try {
      const res = await userApi.changePassword(params);
      if (res.status === 'success') {
        return Promise.resolve(res);
      }
      return thunkAPI.rejectWithValue(res);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
